export type AIMessageMetadata = {
  scores: {
    categoryScores: Map<string, number>;
    totalScore: number;
  };
  verification: {
    results: Array<{
      step: string;
      content: string;
      verification: string;
      explanation: string;
      probability: number;
    }>;
    finalAnswer: { outcome: string; explanation: string; probability: number };
  };
};

export interface SessionChatMessage {
  id?: string;
  msg: string;
  date: string;
  isAIMessage: boolean;
  isRated: boolean;
  metadata?: AIMessageMetadata;
}

export enum SessionChatRunStatus {
  THINKING = "THINKING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}

export enum SessionEventStatus {
  SUGGESTED = "SUGGESTED",
  CONFIRMED = "CONFIRMED",
}

export type SessionEvent = {
  id?: string;
  title: string;
  details: string;
  status: SessionEventStatus;
  date?: string; // Date format "YYYY-MM-DD"
};
