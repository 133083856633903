import {
  Action,
  PayloadAction,
  ThunkAction,
  createSlice,
} from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { NewClientData } from "src/content/mia/TopSection/NewClientButton";
import { AppThunk, RootState } from "src/store";

import {
  AIMessageMetadata,
  SessionChatMessage,
  SessionChatRunStatus,
  SessionEvent,
} from "./model";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/mia`;

export interface CreateClientResponse {
  clientId: string;
  sessionId: string;
}
export enum ClientSessionStatus {
  ERROR = "ERROR",
  THINKING = "THINKING",
  WAITING_FOR_USER_INPUT = "WAITING_FOR_USER_INPUT",
  INITIALISING = "INITIALISING",
  COMPLETED = "COMPLETED",
}

export enum ClientSessionStage {
  INTAKE = "INTAKE",
  CHAT = "CHAT",
}

export interface SessionScores {
  psychosocialScore: number;
  clinicalScore: number;
  runId: string;
}

export interface ClientSessionDetails {
  summary?: string;
  assumptions?: string;
  missingInfo?: string;
  reason?: string;
  risk?: string;
  riskScore?: number;
  selfHarmDimension?: string;
  selfHarmDimensionScore?: number;
  selfHarmDimensionScoreReason?: string;
  selfHarmDimensionNiceToHave: string;
  physicalHealthDimension?: string;
  physicalHealthDimensionScore?: number;
  physicalHealthDimensionScoreReason?: string;
  physicalHealthDimensionNiceToHave?: string;
  comorbidityDimension?: string;
  comorbidityDimensionScore?: number;
  comorbidityDimensionScoreReason?: string;
  comorbidityDimensionNiceToHave?: string;
  functioningDimension?: string;
  functioningDimensionScore?: number;
  functioningDimensionScoreReason?: string;
  functioningDimensionNiceToHave?: string;
  illnessTypeDimension?: string;
  illnessTypeDimensionScore?: number;
  illnessTypeDimensionScoreReason?: string;
  illnessTypeDimensionNiceToHave?: string;
  inferences?: string;
  differentialDiagnosis?: string;
  likelyTreatmentGoals?: string;
  blocksTreatmentGoals?: string;
  firstLineTreatment?: string;
  secondLineTreatment?: string;
  timelineMilestones?: string;
  preMortemAssessment?: string;
  prognosis?: string;
  scores?: SessionScores;
}

export interface ClientSession {
  sessionId: string;
  clientId: string;
  clientFirstname: string;
  clientLastname: string;
  status: ClientSessionStatus;
  currentStage: string;
  sessionStage: ClientSessionStage;
  currentRunId: string;
  details?: ClientSessionDetails | null;
  question?: string | null;
  intakeProgressPerc: number;
  intakeNotes?: string;
  lastRunId: string;
}

export interface ClientSessionAction {
  actionId: string;
  sessionId: string;
  runId: string;
  type: string;
  title: string;
  group: string;
  inputData: string;
  result: string;
  resultType: string;
  feedbackGiven: boolean;
  isComplete: boolean;
}

export interface ClientSessionActionsResponse {
  actions: ClientSessionAction[];
  pageSize: number;
  pageNumber: number;
}

export interface ClientSearchRequest {
  searchTerm: string;
  maxResults?: number;
}

export interface ClientBasicInfo {
  clientId: string;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth: string;
}

export interface ClientSearchResponse {
  clients: ClientBasicInfo[];
  totalResults: number;
}

export interface SessionChatResponse {
  messages: SessionChatMessage[];
  runStatus: SessionChatRunStatus;
}

export enum MessageResponseType {
  TEXT = "TEXT",
}

export interface MessageResponseResult {
  id?: string;
  msg: string;
  status: SessionChatRunStatus;
  responseType: MessageResponseType;
  metadata?: AIMessageMetadata;
  replyToUserMessageId: string;
}

export interface SessionEventsResponse {
  events: SessionEvent[];
}

export interface ClientSessionSummary {
  sessionId: string;
  clientId: string;
  status: string;
  createdOnUtc: string;
  title: string;
}

export interface MiaState {
  recentSessions: ClientSessionSummary[];
  clientId: string;
  sessionId: string;
  clientSession: ClientSession;
  messages: SessionChatMessage[];
  actions: ClientSessionAction[];
  lastMessageId: string;
  isProcessingMessage: boolean;
  hasLoadedPage: boolean;
}

const initialState: MiaState = {
  recentSessions: [],
  clientId: null,
  sessionId: null,
  clientSession: null,
  messages: [],
  actions: [],
  lastMessageId: null,
  isProcessingMessage: false,
  hasLoadedPage: false,
};

const slice = createSlice({
  name: "mia",
  initialState,
  reducers: {
    loadRecentSessions(state, action: PayloadAction<ClientSessionSummary[]>) {
      return {
        ...state,
        recentSessions: action.payload,
      };
    },
    loadNewClient(state, action: PayloadAction<CreateClientResponse>) {
      const { clientId, sessionId } = action.payload;
      return {
        ...state,
        clientId,
        sessionId,
      };
    },
    loadClientId(state, action: PayloadAction<string>) {
      return {
        ...state,
        clientId: action.payload,
      };
    },
    loadSessionId(state, action: PayloadAction<string>) {
      return {
        ...state,
        sessionId: action.payload,
      };
    },
    loadClientSession(state, action: PayloadAction<ClientSession>) {
      return {
        ...state,
        clientSession: action.payload,
      };
    },
    loadMessages(state, action: PayloadAction<SessionChatMessage[]>) {
      return {
        ...state,
        messages: action.payload,
      };
    },
    loadNewMessage(state, action: PayloadAction<SessionChatMessage>) {
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    },
    loadActions(state, action: PayloadAction<ClientSessionAction[]>) {
      return {
        ...state,
        actions: action.payload,
      };
    },
    loadLastMessageId(state, action: PayloadAction<string>) {
      return {
        ...state,
        lastMessageId: action.payload,
      };
    },
    loadNewMessageWithId(
      state,
      action: PayloadAction<{ message: SessionChatMessage; messageId: string }>,
    ) {
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
        lastMessageId: action.payload.messageId,
      };
    },
    setProcessingState(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isProcessingMessage: action.payload,
      };
    },
    setHasLoadedPage(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasLoadedPage: action.payload,
      };
    },
    updateMessagesAfterCancel(state) {
      const { lastMessageId } = state;
      const indexOflastMessageId = state.messages.findIndex(
        (element) => element.id === lastMessageId,
      );

      if (indexOflastMessageId !== -1) {
        const updatedMessages = state.messages.slice(0, indexOflastMessageId);
        const newLastMessageId =
          updatedMessages.length > 0
            ? updatedMessages[updatedMessages.length - 1].id
            : null;
        return {
          ...state,
          lastMessageId: newLastMessageId,
          actions: [],
          messages: updatedMessages,
        };
      }
      return state;
    },
    clearSession(state) {
      return {
        ...state,
        clientId: null,
        sessionId: null,
        clientSession: null,
        messages: [],
        actions: [],
        lastMessageId: null,
        isProcessingMessage: false,
        hasLoadedPage: false,
      };
    },
  },
});

// ----------------------------------------------------- //
// ------------------- HELPER FUNCTIONS ---------------- //
const loadUserMessage =
  (message: string, messageId: string): AppThunk =>
  async (dispatch) => {
    const userMessage: SessionChatMessage = {
      id: messageId,
      msg: message,
      date: new Date().toISOString(),
      isAIMessage: false,
      isRated: false,
    };
    dispatch(
      slice.actions.loadNewMessageWithId({ message: userMessage, messageId }),
    );
  };

// ---------------------------------------------------- //
// ------------------- GET REQUESTS ------------------- //
export const fetchRecentSessions =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
      const response = await axios.get(`${API_PREFIX}/clients/sessions/recent`);
      dispatch(slice.actions.loadRecentSessions(response.data.sessions)); // Load sessions
    } catch (error) {
      console.error("Error fetching client sessions for sidebar:", error);
    }
  };

export const getClientLatestSession =
  (clientId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<ClientSession> = await axios.get(
        `${API_PREFIX}/clients/${clientId}/sessions/latest`,
      );
      dispatch(slice.actions.loadClientSession(response.data));
      dispatch(slice.actions.loadSessionId(response.data.sessionId));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getClientSessionActions =
  (
    clientId: string,
    sessionId: string,
  ): AppThunk<Promise<ClientSessionAction[]>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<ClientSessionActionsResponse> =
        await axios.get(
          `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/actions`,
        );
      const { actions } = response.data;
      dispatch(slice.actions.loadActions(actions));

      return actions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getClientSessionMessages =
  (
    clientId: string,
    sessionId: string,
  ): AppThunk<Promise<SessionChatMessage[]>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<SessionChatResponse> = await axios.get(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages`,
      );
      const { messages } = response.data;
      dispatch(slice.actions.loadMessages(messages));

      if (messages && messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        // Case when the last message is a user message
        if (!lastMessage.isAIMessage) {
          const lastMessageId = lastMessage.id;
          dispatch(slice.actions.loadLastMessageId(lastMessageId));
        }
        // Case when the last message is an AI message
        else if (messages.length >= 2) {
          const lastUserMessage = messages[messages.length - 2];
          const lastMessageId = lastUserMessage.id;
          dispatch(slice.actions.loadLastMessageId(lastMessageId));
        }
      }

      return messages;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getMessageActions =
  (
    clientId: string,
    sessionId: string,
    messageId: string,
  ): AppThunk<Promise<ClientSessionAction[]>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<ClientSessionActionsResponse> =
        await axios.get(
          `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages/${messageId}/actions`,
        );
      const { actions } = response.data;

      // Only load actions if there are any for the message
      if (actions.length > 0) dispatch(slice.actions.loadActions(actions));

      return actions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getResponseToUserMessage =
  (clientId: string, sessionId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<MessageResponseResult> = await axios.get(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages/${messageId}/response`,
      );

      const { id, msg, status, metadata } = response.data;

      if (status === SessionChatRunStatus.CANCELLED) {
        dispatch(slice.actions.setProcessingState(false));
      }
      if (status === SessionChatRunStatus.ERROR) {
        dispatch(slice.actions.setProcessingState(false));
        // TODO: Show error message to user
        console.error("Error processing user message");
      }
      if (status === SessionChatRunStatus.COMPLETED) {
        const responseMessage: SessionChatMessage = {
          id,
          msg,
          date: new Date().toISOString(),
          isAIMessage: true,
          isRated: false,
          metadata,
        };
        dispatch(slice.actions.loadNewMessage(responseMessage));
        dispatch(slice.actions.setProcessingState(false));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getSessionEvents = async (
  clientId: string,
  sessionId: string,
): Promise<SessionEvent[]> => {
  try {
    const response: AxiosResponse<SessionEventsResponse> = await axios.get(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events`,
    );
    return response.data.events;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// ----------------------------------------------------- //
// ------------------- POST REQUESTS ------------------- //
export const createClient =
  (
    client: NewClientData,
  ): ThunkAction<
    Promise<{ clientId: string; sessionId: string }>,
    RootState,
    null,
    Action<string>
  > =>
  async (dispatch) => {
    try {
      // Clear old session data before creating a new client
      dispatch(slice.actions.clearSession());

      const response: AxiosResponse<CreateClientResponse> = await axios.post(
        `${API_PREFIX}/clients`,
        {
          ...client,
          dateOfBirth: client.dateOfBirth.format("YYYY-MM-DD"), // required format from pydantic date in backend
        },
      );
      const { clientId, sessionId } = response.data;
      dispatch(slice.actions.loadNewClient({ clientId, sessionId }));
      dispatch(slice.actions.setProcessingState(true));

      dispatch(fetchRecentSessions()); // Refetch recent sessions after creating a new client

      return { clientId, sessionId };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const processUserMessage =
  (clientId: string, sessionId: string, message: string): AppThunk =>
  async (dispatch) => {
    // Process user message from the chat box in Chat View
    try {
      const response: AxiosResponse<{ messageId: string }> = await axios.post(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages`,
        {
          message,
        },
      );
      dispatch(loadUserMessage(message, response.data.messageId));
      dispatch(slice.actions.setProcessingState(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const processUserResponse =
  (clientId: string, sessionId: string, input: string): AppThunk =>
  async (dispatch) => {
    // Process user response from user input box in Activity View
    try {
      const response: AxiosResponse<{ messageId: string }> = await axios.post(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/response`,
        {
          response: input,
        },
      );
      dispatch(loadUserMessage(input, response.data.messageId));
      dispatch(slice.actions.setProcessingState(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const searchClients = async (
  searchTerm: string,
): Promise<ClientSearchResponse> => {
  try {
    const searchRequest: ClientSearchRequest = { searchTerm };
    const response = await axios.post<ClientSearchResponse>(
      `${API_PREFIX}/clients/search`,
      searchRequest,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelMessage =
  (clientId: string, sessionId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages/${messageId}/cancel`,
      );
      dispatch(slice.actions.updateMessagesAfterCancel());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const createSessionEvent = async (
  clientId: string,
  sessionId: string,
  event: SessionEvent,
): Promise<void> => {
  try {
    await axios.post(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events`,
      event,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateSessionEvent = async (
  clientId: string,
  sessionId: string,
  eventId: string,
  event: SessionEvent,
): Promise<void> => {
  try {
    await axios.put(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events/${eventId}`,
      event,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteClient =
  (clientId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      await axios.delete(`${API_PREFIX}/clients/${clientId}`);
      dispatch(fetchRecentSessions());
    } catch (error) {
      console.error("Failed to delete client:", error);
    }
  };

export const deleteSessionEvent = async (
  clientId: string,
  sessionId: string,
  eventId: string,
): Promise<void> => {
  try {
    await axios.delete(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events/${eventId}`,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const { reducer } = slice;

export default slice;
