import { ReactNode, useEffect, useState } from "react";

import axios from "axios";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useParams } from "react-router-dom";

import DealLogin from "./DealLogin";

const OTPAuthenticated = ({ children }: { children: ReactNode }) => {
  const [isOtpAuthenticated, setIsOtpAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { negotiationId } = useParams();

  // Function to check if deal session is authenticated
  const verifyDealSessionToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/authenication`,
      );
      setIsOtpAuthenticated(response.data.isAuthenticated);
    } catch (error) {
      console.error("Error verifying OTP session:", error);
      setIsOtpAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpVerified = () => {
    verifyDealSessionToken();
  };

  useEffect(() => {
    verifyDealSessionToken();
  }, []);

  if (isLoading) {
    return null;
  }

  const recaptchaSiteKey = "6Lc0fHUqAAAAAP4-k43ue0scjAjq7nGnmu-ZZwwW";

  if (!isOtpAuthenticated) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
        <DealLogin onOtpVerified={handleOtpVerified} />
      </GoogleReCaptchaProvider>
    );
  }

  return <>{children}</>;
};

export default OTPAuthenticated;
